/* eslint-disable no-console,no-undef, camelcase */
import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Datetime from 'react-datetime';
import _, { isNull } from 'lodash';
import 'isomorphic-fetch';
import { toast } from 'react-toastify';

import { SaveButton, CustomCheckbox, CheckMark, ButtonContainer, InviteContainer, InviteAmigo,
  NameContainer, ImageInput, ImageContentContainer, InputContainer, StartDateContainer, DateJoiner, CustomMenuItem, StyledDateTime,
  TitleContainer, FieldTitle, ImageContainer, AmigosContainer, TopContainer, ScrollableContainer, DateIconContainer,
  RemovePhotoButton, LocationContainer } from '../CreateEvent/styles';
import { ActivityTypeContainer, ActivityDropdown, SurvivorAverage, SurvivorChallengeSection } from '../CreateChallengeForm/styles';

import Waiting from '../Waiting';

import {getAllCompanies, editChallengeAction, getUserCompanyDetails, getSurveyLibraries} from '../../redux/actions';

import { getOrientation, resetOrientation, convertDateInTimezone, checkImage, getPermissionStatus } from '../../utils/methods';

// import { challengeModeItems, updatedTierPoints} from '../../../mockData.json';
import { groupActivityTypesItems, activityTypesItems, showChallengeEmailNotificationInfo } from '../../../mockData';

import { ImageUrl, imageErrorMessage } from '../../utils/constants';
import { RadioButtonContainer } from '../CreateRecipe/styles';
import RadioButton from "../CustomRadioButton";
import {FirstPart} from '../BehaviorChallenge/styles';
import {withTranslation} from 'react-i18next';
import CommonInputBox from '../common/CommonInputBox/CommonInputBox';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';
import LazyImage from '../common/LazyImage/LazyImage';
class ChallengeForm extends Component {

  constructor(props) {
    super();
    const { survivorChallengeData, isGroupChallenge, /*role,*/ surveyLibraries} = props;
    let ImageName = '';
    if(!_.isEmpty(survivorChallengeData)) {
      if(survivorChallengeData.imageName) {
        ImageName = survivorChallengeData.imageName;
      } else {
        const Array = survivorChallengeData.imgSrc.split('/');
        ImageName = Array[Array.length - 1];
      }
    }
    let updatedTiers = updatedTierPoints;
    // role === 'WELLNESS_CHAMPION' && updatedTiers.splice(3);
    let tierPointsIndex = updatedTierPoints.findIndex((data) => data.value == _.get(survivorChallengeData, 'challengePointValue', 10));
    let surveyIndex = surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries.findIndex((data) => data.id === survivorChallengeData.surveyId) : -1;
    this.state = {
      title: _.get(survivorChallengeData, 'title', ''),
      date: _.get(survivorChallengeData, 'date', moment().add(1, 'days').format('MM/DD/YYYY')),
      endDate: _.get(survivorChallengeData, 'endDate', moment().add(1, 'days').format('MM/DD/YYYY')),
      description: _.get(survivorChallengeData, 'description', ''),
      imageName: ImageName,
      time: _.get(survivorChallengeData, 'time', moment().format('hh:mm a')),
      endTime: _.get(survivorChallengeData, 'endTime', moment().format('hh:mm a')),
      showDateTimeInput: '',
      activityType: isGroupChallenge ? survivorChallengeData.activityType === 'Total Steps' ? 'Total Group Steps' :  survivorChallengeData.activityType === 'Total Calories' ? 'Total Group Calories' : 'Total Group Distance' : _.get(survivorChallengeData, 'activityType', 'Total Distance'),
      challengePointValue: tierPointsIndex == -1 ? 10 :_.get(survivorChallengeData, 'challengePointValue', 10),
      challengeMode: _.get(survivorChallengeData, 'challengeMode', 'Team'),
      weeklyIncrement: (_.get(survivorChallengeData, 'weeklyIncrement', 0)),
      survivorAverage: (_.get(survivorChallengeData, 'survivorAverage', 0)),
      amigosToInvite: [],
      openDateTimePicker: '',
      imgSrc: _.get(survivorChallengeData, 'imgSrc', ''),
      companies: survivorChallengeData.companies ? survivorChallengeData.companies : [],
      location: survivorChallengeData.locations ? survivorChallengeData.locations : [],
      apiChecker: (convertDateInTimezone(survivorChallengeData.activeDate)._d < moment()._d && convertDateInTimezone(survivorChallengeData.finishDate)._d >= moment()._d) ? true : false,
      isSpouse: survivorChallengeData.isSpouse,
      isDependent: survivorChallengeData.isDependent,
      isEmployee: survivorChallengeData.isEmployee,
      challengeLength: survivorChallengeData.challengeLength ?  survivorChallengeData.challengeLength : '',
      challengePointKey: this.updatedTierPoints[tierPointsIndex == -1 ? 0 : tierPointsIndex].key,
      challengePoints: updatedTiers,
      emailOption: {
        "launch_challenge_email": survivorChallengeData.launch_challenge_email,
        "challenge_reminder_email": survivorChallengeData.challenge_reminder_email,
        "challenge_complete_email": survivorChallengeData.challenge_complete_email
      },
      companiesPermission: false,
      locationPermission: false,
      selectedOption: isNull(survivorChallengeData.surveyId) ? 1 : 0,
      surveyId: survivorChallengeData.surveyId,
      surveyType: surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries[surveyIndex === -1 ? 0 : surveyIndex].survey_name : '',
    }
  }

  updatedTierPoints=[
    {"key": "Tier 1: 10 points", "value": 10},
    {"key": "Tier 2: 25 points", "value": 25},
    {"key": "Tier 3: 100 points", "value": 100},
    {"key": "Tier 4: 250 points", "value": 250},
    {"key": "Tier 5: 500 points", "value": 500}
  ]

  challengeModeItems= [
    "Team",
    "Solo"
  ]

  checkBase64(image) {
    if(image) {
      let arr = image.split('/');
      if(arr[0] !== 'challenge-image') {
        return true;
      }
      else {
        return false;
      }
    }
  }

  componentDidMount() {
    const companiesPermission = getPermissionStatus("Show companies in challenge", this.props.userPermissions);
    const locationPermission = getPermissionStatus("Show locations in challenge", this.props.userPermissions);
    this.props.getSurveyLibraries();
    if(companiesPermission) {
      if(!this.props.allCompanies){
        this.props.fetchAllCompanies();
      }
    } else {
      this.props.fetchCompanyForUser();
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
    this.setState({
      companiesPermission: companiesPermission,
      locationPermission: locationPermission
    })
    document.addEventListener('mousedown', this.handleClick);
  }

  componentDidUpdate(prevProps) {
    if(!this.state.companiesPermission && this.props.userCompany !== prevProps.userCompany) {
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  onChangeLocation = (id) => {
    const { location } = this.state;
    if(location.includes(id)) {
      let index = location.findIndex((item) => item === id);
      if(index > -1) {
        location.splice(index, 1);
      }
    } else {
      location.push(id);
    }
    this.setState({
      location: location
    })
  }
  onChangeEmail=(key)=>{
    const {emailOption} = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({emailOption: emailOption});
  }

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { location } = this.state;
    if(location.length == locationDetails.length) {
      this.setState({
        location: []
      })
    }
    else {
      let arr = [];
      for(let i=0; i< locationDetails.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        location: arr
      })
    }
  }

  handleClick = (e) => {
    if(this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        openDateTimePicker: ''
      });
    }
  };

  setDatePickerWrapper = (node) =>  {
    this.datePickerWrapper = node;
  };

  onChange = (e) => {
    let file = e.target.files[0];
    if(checkImage(e.target.files)) {
      if((file?.size/1000000) <= 20){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const array = document.getElementById('edit-challenge-file').value.split("\\");
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            imageName: array[array.length - 1],
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: baseImage
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('uploadPhoto').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('edit-challenge-file').value = '';
    }
  };

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  removePhoto = () => {
    document.getElementById('edit-challenge-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };

  changeDateTimeView = (value) => {
    if(this.state.showDateTimeInput === '') {
      this.setState({
        date: moment(moment().add(1, 'days')._d).format('MM/DD/YYYY'),
        endDate: moment(moment().add(1, 'days')._d).format('MM/DD/YYYY'),
        time: '12:00 am',
        endTime: '11:59 pm',
        showDateTimeInput: value
      })
    } else {
      this.setState({
        date: moment(moment()._d).format('MM/DD/YYYY'),
        endDate: moment(moment()._d).format('MM/DD/YYYY'),
        time: moment().format('hh:mm a'),
        endTime: moment().format('hh:mm a'),
        showDateTimeInput: '',
      });
    }
  };

  onSelect = (name, value, key='') => {
    (name == 'challengePointValue') ? 
      this.setState({
        [name]: value,
        challengePointKey: key
      }) : 
      this.setState({
        [name]: value,
      });
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if(showDateTimeInput === 'date') {
      this.setState({
        date: moment(e._d).format('MM/DD/YYYY'),
        time: moment(e._d).format('hh:mm a'),
        endDate: moment(e._d).format('MM/DD/YYYY'),
        endTime: '11:59 pm',
        openDateTimePicker: '',
      });
    } else if(stateName.includes('ate')) {
      this.setState({
        openDateTimePicker: '',
        [stateName]: moment(e._d).format('MM/DD/YYYY'),
      });
    } else {
      this.setState({
        [stateName]: moment(e._d).format('hh:mm a'),
        openDateTimePicker: stateName
      });
    }
  };

  showDatePicker = (value) => {
    if(this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: ''
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { title, date, description, imgSrc, endDate, activityType, challengePointValue, challengeMode, weeklyIncrement,
      survivorAverage, companies, location, apiChecker, isSpouse, isDependent, isEmployee, challengeLength, emailOption, locationPermission, surveyId} = this.state;
    const { isSurvivorChallenge, EditChallengeAction, challengeId, isGroupChallenge} = this.props;
    const challengeType = isSurvivorChallenge ? 'survivor' : isGroupChallenge ? 'new_group' : 'group';
    let survivorEndDate = moment(moment(date).add((challengeLength*7)-1, 'days')._d).format('MM/DD/YYYY');
    const challenge = {
      title,
      companies: companies,
      body: description,
      image : imgSrc,
      event_type: isGroupChallenge ? activityType === 'Total Group Steps' ? 'Total Steps' :  activityType === 'Total Group Calories' ? 'Total Calories' : 'Total Distance' : activityType,
      end_date: (isSurvivorChallenge || isGroupChallenge) ? moment(survivorEndDate).format('YYYY-MM-DD') + '' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss") : moment(endDate).format('YYYY-MM-DD') + ' ' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss"),
      start_date: moment(date).format('YYYY-MM-DD') +  ' ' + moment('12:00:00 AM', "hh:mm:ss a").format("HH:mm:ss"),
      survivor_average: survivorAverage,
      weekly_increment: weeklyIncrement,
      challenge_point: challengePointValue,
      challenge_mode: isSurvivorChallenge ? challengeMode : '',
      challenge_type: challengeType,
      isSurvivorChallenge: isSurvivorChallenge,
      id: challengeId,
      imageUpdated: this.checkBase64(imgSrc) ? 1 : 0,
      ...emailOption,
      challengeLength: isSurvivorChallenge ? this.state.challengeLength : 3,
      survey_id: surveyId
    };
    if(locationPermission) {
      challenge.company_city_states = location;
      challenge.challenge_for_spouse = isSpouse;
      challenge.challenge_for_dependent = isDependent;
      challenge.is_for_employee = isEmployee
    }
    if(apiChecker) {
      challenge.is_challenge_started_or_over = 1;
    }
    EditChallengeAction(challenge);
  };

  onCheckboxChange = (element) => {
    const { companies } = this.state;
    if(companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if(index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies
    })
  };

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if(this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies
    })
  };

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if(charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };
  toggleRadioButton = (e) => {
    this.setState({selectedOption: parseInt(e.target.value,10)}, ()=>{});
  };
  onSelectSurveyType = (name, value, id) => {
    this.setState({
      [name]: value,
      surveyId: id
    });
  };
  render() {
    const { title, date, description, imageName, imgSrc, showDateTimeInput, endDate, activityType, challengePointValue,
      challengeMode, weeklyIncrement, survivorAverage, openDateTimePicker, companies, location, apiChecker, isSpouse, isDependent, isEmployee, challengeLength, challengePointKey, challengePoints, emailOption, companiesPermission, locationPermission, selectedOption, surveyType, surveyId} = this.state;
    const { isSurvivorChallenge, MandatoryFields, allCompanies, userCompany, locationDetails, companyDetails, isGroupChallenge, surveyLibraries, t} = this.props;
    let isDisabled = false;
    let emailOptionKeys = Object.keys(emailOption);
    const survivorChallengeLengthDropdown = [3,4,5,6,7,8,9,10,11,12];
    MandatoryFields.map((field) => {
      if(!this.state[field] || !this.state.imgSrc || !this.state.title.trim() || !this.state.description.trim() || _.isEmpty(this.state.companies)) {
        isDisabled = true;
      }
    });

    if(locationPermission) {
      if(locationDetails && locationDetails.length > 0 && location.length < 1 && !(this.state.isSpouse || this.state.isDependent)) {
        isDisabled = true;
      }
      if(locationDetails && locationDetails.length === 0 && companyDetails['spouse_or_dependent']) {
        if(!(this.state.isSpouse || this.state.isDependent || this.state.isEmployee)){
          isDisabled = true;
        }
      }
    } 

    if(isSurvivorChallenge && (survivorAverage === '' || weeklyIncrement == '' || challengeMode == '')) {
      isDisabled = true;
    }
    const challengeActivityTypes = isGroupChallenge ? groupActivityTypesItems : activityTypesItems;
    const yesterday = Datetime.moment();
    const valid = (currentDate) => showDateTimeInput === 'date' ? currentDate.isAfter(Datetime.moment()) : currentDate.isAfter(yesterday);

    if((companiesPermission && !allCompanies) || (locationPermission && !userCompany)) {
      return <Waiting />
    }

    const disabledFunctions = apiChecker;
    const disabledEmailOptionStatus = true;
    return (
      <div>
        <TitleContainer>
          <FieldTitle>{t("Challenge Title")}:</FieldTitle>
          <CommonInputBox
            placeholder={t("Type title here...")}
            name="title"
            onChange={this.onChangeInput}
            value={title}
            maxLength="100"
            disabled={disabledFunctions}
          />
        </TitleContainer>
        <ImageContainer>
          <FieldTitle>{t("Challenge Image")}:</FieldTitle>
          <div>
            {imgSrc &&
            <div>
              <img src={this.checkBase64(imgSrc) ? imgSrc : `${ImageUrl}/${imgSrc}`}/>
              <ImageContentContainer>
                { this.checkBase64(imgSrc) && <div>{imageName}</div>}
                <RemovePhotoButton color="#aaaaaa" addMargin onClick={this.removePhoto} disabled={disabledFunctions}>{t("Remove photo")}</RemovePhotoButton>
              </ImageContentContainer>
            </div>
            }
            <ImageInput htmlFor="edit-challenge-file" showAtBottom={imageName !== ''} disabled={disabledFunctions}>
              {imageName ? t('browse new') : t('browse')}
              <input
                id="edit-challenge-file"
                type="file"
                name="user"
                multiple={false}
                onChange={(e) => this.onChange(e)}
                onClick={(e) => e.target.files[0] && this.onChange(e)}
                accept=".jpeg, .png, .jpg"
                disabled={disabledFunctions}
              />
            </ImageInput>
          </div>
        </ImageContainer>
        <ImageContainer>
          <ActivityTypeContainer>
            <FieldTitle fullWidth="100%">{t("Activity Type")}:</FieldTitle>
            <ActivityDropdown
              title={t(activityType)}
              id="dropdown-basic"
              disabled={disabledFunctions}
            >
              {
                challengeActivityTypes.map((item, index) => (
                  <CustomMenuItem
                    eventKey={index}
                    key={index}
                    onSelect={() => this.onSelect('activityType', item)}
                    active={activityType === item}
                  >
                    {t(item)}
                  </CustomMenuItem>
                ))
              }
            </ActivityDropdown>
          </ActivityTypeContainer>
          <SurvivorChallengeSection>
            {
              (isSurvivorChallenge || isGroupChallenge) &&
                <SurvivorAverage>
                  <FieldTitle>{isGroupChallenge ? t('Team Goal:') : t('Survivor Average:')}</FieldTitle>
                  <CommonInputBox
                    type='number'
                    name='survivorAverage'
                    value={activityType.includes('Distance') ? parseFloat(survivorAverage) : parseInt(survivorAverage,10)}
                    onChange={this.onChangeInput}
                    pattern='[0-9]*'
                    step=".01"
                    min="0"
                    onKeyPress={(e) => this.isNumberKey(e)}
                    disabled={disabledFunctions}
                  />
                </SurvivorAverage>
            }{
              isSurvivorChallenge &&
              <SurvivorAverage>
                <FieldTitle>{t("Weekly Increment")}:</FieldTitle>
                <CommonInputBox
                  type='number'
                  name="weeklyIncrement"
                  value={parseFloat(weeklyIncrement)}
                  onChange={this.onChangeInput}
                  pattern='[0-9]*'
                  step=".01"
                  min="0"
                  onKeyPress={(e) => this.isNumberKey(e)}
                  disabled={disabledFunctions}
                />
              </SurvivorAverage>
            }
          </SurvivorChallengeSection>
        </ImageContainer>
        <ImageContainer>
          <ActivityTypeContainer>
            <FieldTitle fullWidth="100%">{t("Challenge Point Value")}:</FieldTitle>
            <ActivityDropdown
              title={t(challengePointKey)}
              id="dropdown-basic"
              disabled={disabledFunctions}
            >
              {
                challengePoints.map((item, index) => (
                  <CustomMenuItem
                    eventKey={index}
                    key={index}
                    onSelect={() => this.onSelect('challengePointValue', item['value'], item['key'])}
                    active={challengePointValue == item['value']}
                  >
                    {t(item['key'])}
                  </CustomMenuItem>
                ))
              }
            </ActivityDropdown>
          </ActivityTypeContainer>
          {
            isSurvivorChallenge &&
            <ActivityTypeContainer challengeMode>
              <FieldTitle fullWidth="100%">{t("Challenge Mode")}:</FieldTitle>
              <ActivityDropdown
                title={t(challengeMode)}
                id="dropdown-basic"
                disabled={disabledFunctions}
              >
                {
                  this.challengeModeItems.map((item, index) => (
                    <CustomMenuItem
                      eventKey={index}
                      key={index}
                      onSelect={() => this.onSelect('challengeMode', item)}
                      active={challengeMode === item}
                    >
                      {t(item)}
                    </CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </ActivityTypeContainer>
          }
        </ImageContainer>
        {surveyId && <ImageContainer>
          <FirstPart>
            <FieldTitle>{t("Add a survey")}:</FieldTitle>
            <RadioButtonContainer justifyContent={"start"}>
              <RadioButton id="1" handler={disabledEmailOptionStatus && this.toggleRadioButton} value={0} isChecked={selectedOption === 0} label={t('Yes')} challengeLeaderBoard={true}/>
              <RadioButton id="2" handler={disabledEmailOptionStatus && this.toggleRadioButton} value={1} isChecked={selectedOption === 1} label={t('No')} challengeLeaderBoard={true}/>
            </RadioButtonContainer>
          </FirstPart>
        </ImageContainer>}
        {selectedOption == 0 ? <TitleContainer><ActivityTypeContainer width="100%" noChildWidth={1}>
          <FieldTitle>{t("Select a survey")}:</FieldTitle>
          <ActivityDropdown
            title={<div className="title">{surveyLibraries.length == 0 ? t('No libraries') : surveyType == '' ? t('Select a library') : t(surveyType)}</div>}
            id={"surveyType"}
            disabled={true}
          >{
              surveyLibraries && surveyLibraries.length > 0 && surveyLibraries.map((list) => (
                <CustomMenuItem key={list.id} active={surveyType == list.survey_name} onSelect={() => this.onSelectSurveyType('surveyType', list.survey_name, list.id)}>{t(list.survey_name)}</CustomMenuItem>
              ))
            }
          </ActivityDropdown>
        </ActivityTypeContainer></TitleContainer> : null
        }
        <TitleContainer addMargin>
          <FieldTitle>{t("Challenge Date")}:</FieldTitle>
          {/* <div>
            <CustomCheckbox>All Day
              <input
                type="checkbox"
                checked={showDateTimeInput === 'date'}
                onChange={() => this.changeDateTimeView('date')}
              />
              <CheckMark checked={showDateTimeInput === 'date'}/>
            </CustomCheckbox>
          </div> */}
          {showDateTimeInput === 'date' ?
            <InputContainer disabled={disabledFunctions}>
              <div ref={(node) => openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                <StyledDateTime
                  open={openDateTimePicker === 'date'}
                  inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true, disabled:disabledFunctions}}
                  dateFormat="MM/DD/YYYY"
                  closeOnSelect={true}
                  closeOnTab={true}
                  timeFormat={false}
                  value={date}
                  onChange={(e) => !disabledFunctions && this.changeDate(e, 'date')}
                  isValidDate={valid}
                  disabled={disabledFunctions}
                />
                <DateIconContainer onClick={() =>!disabledFunctions && this.showDatePicker('date')} disabled={disabledFunctions}>
                  <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                </DateIconContainer>
              </div>
            </InputContainer> :
            <div>
              <StartDateContainer isSurvivorChallenge={isSurvivorChallenge}>
                <InputContainer disabled={disabledFunctions}>
                  <div ref={(node) => !disabledFunctions && openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                    <StyledDateTime
                      open={openDateTimePicker === 'date'}
                      inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true, disabled:disabledFunctions}}
                      dateFormat="MM/DD/YYYY"
                      closeOnSelect={true}
                      closeOnTab={true}
                      timeFormat={false}
                      value={date}
                      onChange={(e) => !disabledFunctions && this.changeDate(e, 'date')}
                      isValidDate={valid}
                      disabled={disabledFunctions}
                    />
                    <DateIconContainer onClick={() => !disabledFunctions && this.showDatePicker('date')} disabled={disabledFunctions}>
                      <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                    </DateIconContainer>
                  </div>
                </InputContainer>
                {/* <InputContainer timeInput>
                  <div ref={(node) => openDateTimePicker === 'time' && this.setDatePickerWrapper(node)}>
                    <StyledDateTime
                      open={openDateTimePicker === 'time'}
                      inputProps={{ placeholder: moment().format('hh:mm a'), readOnly: true}}
                      dateFormat={false}
                      closeOnTab={true}
                      value={time}
                      onChange={(e) => this.changeDate(e, 'time')}
                      timeInput
                    />
                    <i className="far fa-clock" onClick={() => this.showDatePicker('time')} />
                  </div>
                </InputContainer> */}
              </StartDateContainer>
              <DateJoiner >to</DateJoiner>
              {!isSurvivorChallenge && !isGroupChallenge ? <StartDateContainer isSurvivorChallenge={isSurvivorChallenge}>
                <InputContainer disabled={disabledFunctions}>
                  <div ref={(node) => openDateTimePicker === 'endDate' && this.setDatePickerWrapper(node)}>
                    <StyledDateTime
                      open={openDateTimePicker === 'endDate'}
                      inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true, disabled:disabledFunctions}}
                      dateFormat="MM/DD/YYYY"
                      closeOnSelect={true}
                      closeOnTab={true}
                      timeFormat={false}
                      value={endDate}
                      onChange={(e) => !disabledFunctions && this.changeDate(e, 'endDate')}
                      isValidDate={valid}
                      disabled={disabledFunctions}
                    />
                    <DateIconContainer onClick={() => !disabledFunctions && this.showDatePicker('endDate')} disabled={disabledFunctions}>
                      <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                    </DateIconContainer>
                  </div>
                </InputContainer>
                {/* <InputContainer timeInput>
                  <div ref={(node) => openDateTimePicker === 'endTime' && this.setDatePickerWrapper(node)}>
                    <StyledDateTime
                      open={openDateTimePicker === 'endTime'}
                      inputProps={{ placeholder: moment().format('hh:mm a'), readOnly: true}}
                      dateFormat={false}
                      closeOnTab={true}
                      value={endTime}
                      onChange={(e) => this.changeDate(e, 'endTime')}
                      timeInput
                    />
                    <i className="far fa-clock" onClick={() => this.showDatePicker('endTime')} />
                  </div>
                </InputContainer> */}
              </StartDateContainer> : null
              }
              {(isSurvivorChallenge || isGroupChallenge) ?
                <ActivityTypeContainer float={'left'} width={'50%'} margin={'-15px 0 0 0'}>
                  <ActivityDropdown
                    title={`${challengeLength} ${t("Weeks")}`}
                    id="dropdown-basic"
                    disabled={disabledFunctions}
                  >
                    {  survivorChallengeLengthDropdown.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelect('challengeLength', item)}
                        active={challengeLength === item}
                      >
                        {`${t(item)} ${t("Weeks")}`}
                      </CustomMenuItem>
                    ))}
                  </ActivityDropdown>
                </ActivityTypeContainer>:  null}
            </div>
          }
        </TitleContainer>
        <TitleContainer>
          <FieldTitle>{t("Email Notifications: Select email option for this Challenge")} </FieldTitle>
          <LocationContainer>
            {
              emailOptionKeys.map((item, index) => (
                <InviteAmigo key={index}  width={'200px'}>
                  <CustomCheckbox disabled={disabledEmailOptionStatus}>
                    <input
                      type="checkbox"
                      checked={emailOption[item] === 1}
                      onChange={() => !disabledEmailOptionStatus && this.onChangeEmail(item)}
                    />
                    <CheckMark checked={emailOption[item] === 1} disabled={disabledEmailOptionStatus}/>
                  </CustomCheckbox>
                  <NameContainer onClick={() => !disabledEmailOptionStatus && this.onChangeEmail( item)} disabled={disabledEmailOptionStatus}>
                    <span>{t("Challenge")}{item == 'launch_challenge_email' ? t(' Creation') : item == 'challenge_reminder_email' ? t(' Reminder') : t(' Completion')} </span>
                  </NameContainer>
                  <div className="showInfoText">{showChallengeEmailNotificationInfo[index]}</div>
                </InviteAmigo>
              ))
            }
          </LocationContainer>
        </TitleContainer>
        {
          companiesPermission &&
          <AmigosContainer>
            <TopContainer>
              <FieldTitle>{t("Invite Companies")}:</FieldTitle>
              <ButtonContainer>
                { !disabledFunctions &&
                  <SaveButton color="#159fc9" onClick={(e) => this.selectAllCompanies(e)}>
                    <i className="fas fa-check"/>
                    {
                      companies.length === allCompanies.length ? t('deselect all companies') : t('select all companies')
                    }
                  </SaveButton>
                }
              </ButtonContainer>
            </TopContainer>
            <InviteContainer>
              <ScrollableContainer>
                {
                  allCompanies.map((company, index) => (
                    <InviteAmigo key={index}>
                      <CustomCheckbox disabled={disabledFunctions}>
                        <input
                          type="checkbox"
                          checked={companies.includes(company.id)}
                          onChange={() => !disabledFunctions && this.onCheckboxChange(company.id)}
                        />
                        <CheckMark checked={companies.includes(company.id)} disabled={disabledFunctions}/>
                      </CustomCheckbox>
                      <NameContainer onClick={() => !disabledFunctions && this.onCheckboxChange(company.id)} disabled={disabledFunctions}>
                        <span>{t(company.company_name)}</span>
                      </NameContainer>
                    </InviteAmigo>
                  ))
                }
              </ScrollableContainer>
            </InviteContainer>
          </AmigosContainer>
        }
        { locationPermission  && (location && location.length > 0 || companyDetails['spouse_or_dependent']) ?
          <AmigosContainer>
            <TopContainer>
              <FieldTitle> {t("Company Locations")}:</FieldTitle>
              <ButtonContainer>
                {/* <SaveButton color="#159fc9" onClick={(e) => this.selectAllLocation(e, locationDetails)}>
                  <i className="fas fa-check"/>
                  {
                    location.length === locationDetails.length ? 'deselect all locations' : 'select all locations'
                  }
                </SaveButton> */}
              </ButtonContainer>
            </TopContainer>
            <InviteContainer>
              <ScrollableContainer>
                <LocationContainer minHeight={location && location.length} >
                  {
                    locationDetails && locationDetails.length > 0 ? locationDetails.map((loc, index) => (
                      <InviteAmigo key={index} hidden={!location.includes(loc.id)}>
                        { location.includes(loc.id) &&
                        <CustomCheckbox disabled={true}>
                          <input
                            type="checkbox"
                            defaultChecked={location.includes(loc.id)}
                          />
                          <CheckMark checked={location.includes(loc.id)}/>
                        </CustomCheckbox>
                        }
                        { location.includes(loc.id) &&
                        <NameContainer disabled={true}>
                          <span>{t(loc.location)}</span>
                        </NameContainer>
                        }
                      </InviteAmigo>
                    )) : null
                  }
                </LocationContainer>
                {companyDetails['spouse_or_dependent'] ?
                  <div style={{display: 'flex'}}>
                    <InviteAmigo hidden={!isSpouse}>
                      <CustomCheckbox disabled={true}>
                        <input
                          type="checkbox"
                          checked={isSpouse}
                        />
                        <CheckMark checked={isSpouse}/>
                      </CustomCheckbox>
                      <NameContainer disabled={true}>
                        <span>{t('Spouse')}</span>
                      </NameContainer>
                    </InviteAmigo>
                    <InviteAmigo hidden={!isDependent}>
                      <CustomCheckbox disabled={true}>
                        <input
                          type="checkbox"
                          checked={isDependent}
                        />
                        <CheckMark checked={isDependent}/>
                      </CustomCheckbox>
                      <NameContainer disabled={true}>
                        <span>{t('Dependent')}</span>
                      </NameContainer>
                    </InviteAmigo>
                    {locationDetails && locationDetails.length == 0 &&
                    <InviteAmigo hidden={!isEmployee}>
                      <CustomCheckbox disabled={true}>
                        <input
                          type="checkbox"
                          checked={isEmployee}
                        />
                        <CheckMark checked={isEmployee}/>
                      </CustomCheckbox>
                      <NameContainer disabled={true}>
                        <span>{t('Employee')}</span>
                      </NameContainer>
                    </InviteAmigo>
                    }
                  </div> : null
                }
              </ScrollableContainer>
            </InviteContainer>
          </AmigosContainer> : null
        }
        <TitleContainer>
          <FieldTitle>{t("About the Challenge")}:</FieldTitle>
          <CommonTextArea
            placeholder={t("Write about challenge here...")}
            rows="8"
            name="description"
            value={description}
            onChange={this.onChangeInput}
            data-gramm_editor="false"
          />
        </TitleContainer>
        <ImageContainer>
          <SaveButton
            addMargin
            disabled={isDisabled}
            onClick={this.onSubmit}
          >
            {t("Save Challenge")}
          </SaveButton>
        </ImageContainer>
      </div>
    )
  }
}

ChallengeForm.propTypes = {
  isSurvivorChallenge: PropTypes.bool.isRequired,
  createChallenge: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  MandatoryFields: PropTypes.array.isRequired,
  survivorChallengeData: PropTypes.object,
  EditChallengeAction: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  fetchAllCompanies: PropTypes.func.isRequired,
  role: PropTypes.string,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  challengeId: PropTypes.number,
  locationDetails: PropTypes.array,
  companyDetails: PropTypes.object,
  isGroupChallenge: PropTypes.bool,
  userPermissions: PropTypes.array,
  surveyLibraries:PropTypes.array,
  getSurveyLibraries:PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  userPermissions: state.profileData.userPermissions,
  surveyLibraries: state.surveys.surveyLibraries,
});

const mapDispatchToProps = (dispatch) => ({
  EditChallengeAction: (data) => dispatch(editChallengeAction(data)),
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  getSurveyLibraries: () => dispatch(getSurveyLibraries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChallengeForm));
